import Slider from '../slider/slider';

(function($){
    let carousel_section = $('.section.s8');
    let lightSliderLoaded = false;
    let counter = $('.counter');
    let counterloaded = false;
    let counterArray = [];
    const isRTL = languageAttributes.languageCode === 'ar';
    const sliderBreakpoints = [
        {
            breakpoint: 2000,
            item: 6,
            slideMove: 3,
            slideMargin: 6
        },
        {
            breakpoint: 1600,
            item: 4,
            slideMove: 3,
            slideMargin: 6
        },
        {
            breakpoint: 950,
            item: 3,
            slideMove: 2,
            slideMargin: 6
        },
        {
            breakpoint: 750,
            item: 2,
            slideMove: 1,
            slideMargin: 6
        },
        {
            breakpoint: 520,
            item: 2,
            slideMove: 1,
            slideMargin: 6
        },
        {
            breakpoint: 420,
            item: 1,
            slideMove: 1,
            slideMargin: 6
        },
    ];

    for (var i =0; i < counter.length; i++){
        counterArray.push(counter[i]);
    }

    function doScrolling() {
        
        if (carousel_section.length > 0 && !lightSliderLoaded &&  typeof window.isInViewport != 'undefined' && window.isInViewport(carousel_section)) {
            new Slider(sliderBreakpoints, isRTL);
            lightSliderLoaded = true;
        }

        if(!counterloaded && counter.length > 0 && window.isInViewport(counter) ){
            counterloaded = true;
            counterArray.forEach(function (item) {
        
                $(item).prop('counter',0).animate({
                 Counter: $(item).attr('data-start')
             }, {
                 duration: 1700,
                 easing: 'swing',
                 step: function (now) {
                    $(item).text(Math.ceil(now));
                 }
                 
             });
             setTimeout(function(){ $(item).text($(item).attr('data-num')); }, 1800);
            
            
            
            });
        }
    
    }
    window.scrollingTasks.push(doScrolling);

})(window.jQuery);

